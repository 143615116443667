<template>
    <b-row class="match-height">
      <b-col
  lg="12"
  > <b-card
      title="Receive Material Request"
    >
      <b-form
          @submit.prevent="submitReceiveMaterialRequest"
  >
        <b-row>
          <b-col cols="12">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <div class="devider" />
        <b-form-group>
          <label for="email">Number :</label>
          <input
v-model="materialRequestNumber"
type="text"
name="Number"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="email">To :</label>
          <input
v-model="materialRequestTo"
type="text"
name="to"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="email">CC :</label>
          <input
v-model="materialRequestCc"
type="text"
name="cc"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Reason :</label>
          <input
v-model="materialRequestReason"
type="text"
name="reason"
class="form-control"
readonly
>
        </b-form-group>
            <b-form-group
                  label="Items *"
                  label-for="chosenItem"
                >
                  <v-select-new
                    v-model="chosenItem"
                    :filterable="true"
                    :options="items"
                    :get-option-label="getOptionLabel"
                    :get-option-key="getOptionKey"
                    @input="putItem"
                    @search="inputSearch"
                  />
                </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col
  cols="6"
  class="text-center font-weight-bold"
  >
                Item name
              </b-col>
              <b-col
  cols="2"
  class="text-center font-weight-bold"
  >
                Unit name
              </b-col>
              <b-col
  cols="2"
  class="text-center font-weight-bold"
  >
                QTY Sent
              </b-col>
              <b-col cols="2" class="text-center font-weight-bold">Action</b-col>
              </b-row>
             <p />
            <b-row
  v-for="(chosen) in chosens"
  :key="chosen.value"
  >
            <br>
              <b-col cols="6">
                  <b-list-group>
                    <b-list-group-item href="#">
                    {{ chosen.label }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              <b-col cols="2">
                  <b-list-group>
                    <b-list-group-item href="#">
                    {{ capitalizeWords(chosen.unit_name) }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              <b-col cols="2" class="text-center">
              <b-form-input
                v-model="chosen.qty"
                :max="chosen.qty"
                readonly
              />
              </b-col>
              <b-col cols="2" class="text-center">
              <b-button
                size="sm"
                type="button"
                variant="danger"
                class="mr-1"
                @click="removeRow(chosen.id)"
              >
                    <feather-icon
                      icon="TrashIcon"
                      size="18"
                    />
              </b-button>
              </b-col>
              <b-col cols="12">
                <br>
              </b-col>
              </b-row>
          </b-col>
          <!-- submit and reset -->
          <b-col offset-md="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="submitButtonDisabled"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem, BFormDatepicker, BFormFile,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

  const VersionCheck = ''
  const file = ''
  const items = []
  const chosenPR = ref()
  const chosens = []
  const shipmentTo = ''
  const itemId = ''
  const materialRequestTo = ''
  const materialRequestCc = ''
  const materialRequestReason = ''
  const materialRequestQtyRequested = 0
  const materialRequestStatus = ''
  const materialRequestId = ''
  const materialRequestNumber = ''
  const materialrequestIsAcceptedBySupervisor = 0
  const materialrequestIsAcceptedByWarehouse = 0
  const materialrequestIsReceived = 0
  const deliverySchedule = ''
  const modalReferenceShow = false
  const itemReference = ''
  const itemLink = ''
  const itemPrice = ''
  const itemDescription = ''
  const contacts = []
  const branches = []
  const references = []
  const currencies = []
  const returns = []
  const chosenBranch = ''
  const chosenContact = ''
  const termOfPayment = ''
  const billingAddress = ''
  const deliveryAddress = ''
  const MaterialRequestDetailId = ''
  const modalReturnShow = false
  const itemCurrency = ''
  const returnItemQty = ''
  const chosenShelf = ''
  const chosenItem = ''
  const shelfs = []
  const modalReferenceHistoryShow = false
  const submitButtonDisabled = false

  export default {
    components: {
      BFormFile,
      BFormDatepicker,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        submitButtonDisabled,
        chosenItem,
        shelfs,
        chosenShelf,
        returns,
        returnItemQty,
        MaterialRequestDetailId,
        modalReturnShow,
        itemCurrency,
        currencies,
        deliveryAddress,
        chosenBranch,
        branches,
        termOfPayment,
        billingAddress,
        modalReferenceHistoryShow,
        chosenContact,
        contacts,
        itemId,
        deliverySchedule,
        file,
        modalReferenceShow,
        itemReference,
        itemLink,
        itemPrice,
        itemDescription,
        references,
      VersionCheck,
      items,
      chosenPR,
      chosens,
      shipmentTo,
      materialRequestTo,
      materialRequestCc,
      materialRequestReason,
      materialRequestQtyRequested,
      materialRequestStatus,
      materialRequestId,
      materialRequestNumber,
      materialrequestIsAcceptedBySupervisor,
      materialrequestIsAcceptedByWarehouse,
      materialrequestIsReceived,
      }
    },
    mounted() {
      const shelfsVariables = process.env.VUE_APP_SHELF_LIST.split(",")
      const shelfsRepeat = process.env.VUE_APP_SHELF_REPEAT.split(",")
      this.shelfs = []
      this.chosens = []
      shelfsVariables.map(s => {
        shelfsRepeat.map(r => {
          this.shelfs.push(`${s}${r}`)
        })
      })
        this.getDetails()
    },
    created() {
      window.setInterval(() => {
        this.removeReadOnly()
      }, 1000)
    },
    methods: {
      removeReadOnly() {
      const searches = document.getElementsByClassName('vs__search')
      for (let i = 0; i < searches.length; i += 1) {
        searches[i].removeAttribute('readonly')
  }
      },
    getDetails() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      const materialRequest = JSON.parse(localStorage.getItem('MaterialRequestDetail'))
      this.materialRequestTo = materialRequest.material_request_to
      this.materialRequestCc = materialRequest.material_request_cc
      this.materialRequestReason = materialRequest.material_request_reason
      this.materialRequestId = materialRequest.material_request_id
      this.materialRequestNumber = materialRequest.material_request_number
      this.materialrequestIsAcceptedByWarehouse = materialRequest.material_request_is_accepted_by_warehouse
      this.materialrequestIsAcceptedBySupervisor = materialRequest.material_request_is_accepted_by_supervisor
      this.materialrequestIsReceived = materialRequest.material_request_is_received !== null ? materialRequest.material_request_is_received : false
      axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_UNSENT}${materialRequest.material_request_id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
            const temp = []
            response.data.data.map(elem => {
              const received = elem.material_request_detail_item_qty_received !== null ? elem.material_request_detail_item_qty_received : 0
              temp.push({
                detail_id: elem.material_request_detail_id,
                itemId: elem.item.item_id,
                label: `${elem.item.item_name} ( ${elem.item.item_code} )`,
                unit_name: elem.item.unit ? elem.item.unit.unit_name : '-',
                name: elem.item.item_name,
                value: elem.material_request_detail_id,
                qty: elem.material_request_detail_item_qty_sent ? parseInt(elem.material_request_detail_item_qty_sent) - parseInt(received) : 0,
                received: 0,
                id: temp.length + 1,
              })
            })
          this.items = temp
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data failed',
                icon: 'CheckIcon',
                variant: 'danger',
              },
            })
        }
        })
    },
    submitReceiveMaterialRequest() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      const item = JSON.parse(localStorage.getItem('MaterialRequestDetail'))
      let totalReceived = 0

      this.chosens.map(o => {
            totalReceived += parseInt(o.qty, 10)
        })
      const id = item.material_request_id
      const body = {
        material_request_id: id,
        material_request_items: JSON.stringify(this.chosens),
        material_request_qty_received: totalReceived,
        time: moment(),
      }
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_RECEIVE_MATERIAL_REQUEST}`
      axios
        .post(url, body, { headers })
        .then(response => {
          this.submitButtonDisabled = false
          if (response.data.success === true) {
            this.chosens = []
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Receive Material Request Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.replace({ name: 'apps-material-request-receive-list' })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Receive Material Request Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Something Went Wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      handleFilesUpload(e) {
          this.file = e.target.files || e.dataTransfer.files
      },
      addReference(val) {
        this.modalReferenceShow = true
        this.itemId = val
      },
      showReference(val) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        this.itemId = val
        this.modalReferenceHistoryShow = true
      axios
        .get(`${process.env.VUE_APP_API_GET_ITEM_LOOKUP}${val}`, { headers })
        .then(response => {
          if (response.data.success === true) {
                this.references = response.data.data
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get References Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get References Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      },
      dateSimple(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
      returnItem(id) {
        this.MaterialRequestDetailId = id
        this.modalReturnShow = true
      },
      saveReturnItem() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Return Item Saved',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      },
      shelfChange(id) {
        this.chosens.map(o => {
          if (o.value === id) {
            o.shelf = this.chosenShelf
          }
        })
      },
        getOptionKey(option) {
          return (option && option.value) || ''
        },
        putItem(val) {
          if (this.chosens.length > 0) {
                let item = null
                this.items.map(elem => {
                  if (elem.value === val.value) {
                    item = {
                      unit_name: elem.unit_name, itemId: elem.itemId, label: elem.label, value: elem.value, qty: elem.qty, received: elem.received, id: this.chosens.length + 1,
                    }
                  }
                })

                // Check if elem.itemId exists in this.chosens
                if (!this.chosens.some(chosenItems => chosenItems.itemId === item.itemId)) {
                this.chosens.push(item)
                }
          } else {
                let item = null
                this.items.map(elem => {
                  if (elem.value === val.value) {
                    item = {
                      unit_name: elem.unit_name, itemId: elem.itemId, label: elem.label, value: elem.value, qty: elem.qty, received: elem.received, id: this.chosens.length,
                    }
                  }
                })

              this.chosens.push(item)
          }
        },
        removeRow(val) {
          this.chosens = this.chosens.filter(obj => (obj.id !== val))
        },
    previous() {
          this.$router.push({ name: 'apps-material-request-receive-add' })
    },
    capitalizeWords(str) {
    // Split the string into words using spaces as the separator
    const words = str.split(' ')
    // Capitalize the first letter of each word and join them back together
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    })
    // Join the capitalized words into a single string with spaces
    return capitalizedWords.join(' ')
    },
    },
  }
  </script>